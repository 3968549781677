import React, { useEffect, useState } from 'react'
import Home from '../componentes/home/home'
import Loader from '../componentes/Loader';

export default function MainPage({items}) {
  // const [currentPage, setCurrentPage] = useState(1)
  // const [postPerPage, setPstPerPage] = useState(8)

  // const indexOfLastPage = currentPage * postPerPage;
  // const indexOfFirstPost = indexOfLastPage - postPerPage;
  // const currentPosts = items.slice(indexOfFirstPost, indexOfLastPage)

  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
      setTimeout(() => {
          setIsLoader(false)
      }, 1800);
  })
  return (
    <>
    {isLoader ? <>< Loader /> </> : <>
    <Home 
    // items={currentPosts}
    />
            </>}
   
    </>
  )
}
