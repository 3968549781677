import React, { useEffect, useState } from 'react'
import vector from '../../componentes/images/Vector2.svg'
import stat from '../../componentes/images/chart-graphic.png'
import fr_1 from '../../componentes/images/Rectangle 34.png'
import fr_2 from '../../componentes/images/Rectangle 34 (1).png'
import fr_3 from '../../componentes/images/Rectangle 34 (2).png'
import fr_4 from '../../componentes/images/Rectangle 34 (3).png'
import './home.css'
import { API_PATH } from '../../tools/constant'
import axios from 'axios'
import PieChart from '../Diagramma/Diagramma'
import Diagramma from '../Diagramma/Diagramma'
import { getByText } from '@testing-library/react'
// import CanvasJSChart from '../Diagramma/Diagramma'
import {getText} from '../locales'

export default function Analiz() {
    
  const [stats, setStats] = useState([])
  const [loader, setLoader] = useState(true)


  const getNews = async () => {
      await axios.get(API_PATH + 'sources/statistics/')
          .then((res) => {
              console.log(res);
              setStats(res.data)
              setLoader(false)
          })
          .catch((err) => {
              console.log(err);
              setLoader(false)
          })
          console.log(getNews);
  }

  useEffect(() => {
      getNews()
  }, [])
  return (
    <>
    <div className="statistika">
                <div className="container">
                    <div className="row for_stats">
                        <div className="col-lg-6">
                           <div className="for_leftt">
                           <div className="name_statistika">
                                <img src={vector} alt="" />
                                <h2 className='h2_text'>{getText("stat1")}</h2>
                            </div>
                            <p className='qadimiy'>{getText("stat2")}</p>
                           
                            <div className="animat">
                                <div className="fr_1">
                                    <img src={fr_1} alt="" />
                                    <p>{getText("stat3")} : {stats.handbooks}</p>
                                </div>
                                <div className="fr_1">
                                    <img src={fr_2} alt="" />
                                    <p>{getText("stat4")} : {stats.lithography}</p>
                                </div>
                                <div className="fr_1">
                                    <img src={fr_3} alt="" />
                                    <p>{getText("stat5")} : {stats.docs}</p>
                                </div>
                                <div className="fr_1">
                                    <img src={fr_4} alt="" />
                                    <p>{getText("stat6")} : {stats.total}</p>
                                </div>
                            </div>
                        
                           </div>
                        </div>

                        <div className="col-lg-6">
                        
                           <div className="for_statistika_anima">
                           <Diagramma />
                           <h1 className='big_dig'>{stats.total}</h1>
                           </div>
                        </div>
                        
                    </div>
                </div>
            </div>
    
    </>
  )
}
