import React, { useEffect, useState } from "react";

import "./navbar.css";
import logo from "../../componentes/images/image 1 (1).png";
import tg from "../images/Frame 94.png";
import fb from "../images/Frame 96.png";
import yb from "../images/Frame 97.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTelegramPlane, FaFacebookF, FaYoutube } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getLanguage, getText } from "../locales";
import { API_PATH, LANGUAGE } from "../../tools/constant";
import { BiMenuAltRight } from "react-icons/bi";
import axios from "axios";

export default function Navbar() {
  const [vil, setVil] = useState([]);
  const [burger, setBurger] = useState(false);
  const [search, setSearch] = useState("");
  const [navbar, setNavbar] = useState(false);
  const [path, setPath] = useState(localStorage.getItem("LOCATION") || "/");

  const changeLanguage = (e) => {
    localStorage.setItem(LANGUAGE, e.target.value);
    document.location.reload(true);
  };

  const location = useLocation();
  const sear = () => {
    localStorage.setItem("SEARCH", JSON.stringify(search));
  };

  const changeNavbar = () => {
    if (window.scrollY >= 30) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    const works = async () => {
      await axios
        .get(API_PATH + `sources/viloyat/`)
        .then((res) => {
          setVil(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    works();
  }, []);
  const navigate = useNavigate();
  const link = (id) => {
    localStorage.setItem("VILOYAT", JSON.stringify(id));
    document.location.reload(true);
    navigate(path);
  };

  window.addEventListener("scroll", changeNavbar);

  return (
    <>
      <div className="Navbar">
        <div className="container">
          <div className="row">
            <div className="col-9 d-flex">
              <div className="left_navbar">
                <Link
                  to="/about"
                  className={`nav_text_1 ${
                    location.pathname === "/about" ? "active2" : ""
                  }`}
                >
                  {getText("nav1")}
                </Link>
                <Link
                  to="/qolyozmalar"
                  className={`nav_text_1 ${
                    location.pathname === "/qolyozmalar" ? "active2" : ""
                  }`}
                >
                  {getText("nav2")}
                </Link>
                <Link
                  to="/toshbosmalar"
                  className={`nav_text_1 ${
                    location.pathname === "/toshbosmalar" ? "active2" : ""
                  }`}
                >
                  {getText("nav3")}
                </Link>
                <Link
                  to="/tarixiyhujjatlar"
                  className={`nav_text_1 ${
                    location.pathname === "/tarixiyhujjatlar" ? "active2" : ""
                  }`}
                >
                  {getText("nav4")}
                </Link>

                <ul class="nav__list">
                  <li class="nav__listitem">
                    {getText("nav5")}
                    <ul class="nav__listitemdrop">
                      {vil &&
                        vil.map((item, index) => {
                          return (
                            <div key={index}>
                              <li onClick={() => link(item.id)}>{item.name}</li>
                            </div>
                          );
                        })}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-3">
              <div className="right_navbar">
                <a href="https://t.me/islommarkazi">
                  <div className="telegramm">
                    <FaTelegramPlane className="iconn" />
                  </div>
                </a>
                <a href="https://www.facebook.com/CentrofIslamiccivilizationinUzbekistan">
                  <div className="telegramm">
                    <FaFacebookF className="iconn" />
                  </div>
                </a>
                <a href="https://www.youtube.com/channel/UCghyr-cYQSU_vMKSQ4WnK4w/videos">
                  <div className="telegramm">
                    <FaYoutube className="iconn" />
                  </div>
                </a>
                <select onChange={changeLanguage} name="" id="">
                  <option value="ky" selected={getLanguage() === "ky"}>
                  ЎЗ
                  </option>
                  <option value="uz" selected={getLanguage() === "uz"}>
                    O'Z
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="navbar_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-7">
              <Link
                onClick={() => setBurger(!burger)}
                style={{ textDecoration: "none" }}
                to="/"
              >
                {" "}
                <div className="top_navbar">
                  <img src={logo} alt="" />
                  <p className="nav_text for_qadimiy">{getText("nav9")}</p>
                </div>
              </Link>
            </div>
            <div className={`col-12  nav_a_box ${burger ? "active2" : ""}`}>
              <div className="col-12 for_obshiy_mobile">
                <div className="left_navbar">
                  <Link
                    onClick={() => setBurger(!burger)}
                    to="/about"
                    className="nav_text_1"
                  >
                    {getText("nav1")}
                  </Link>
                  <Link
                    onClick={() => setBurger(!burger)}
                    to="/qolyozmalar"
                    className="nav_text_1"
                  >
                    {getText("nav2")}
                  </Link>
                  <Link
                    onClick={() => setBurger(!burger)}
                    to="/toshbosmalar"
                    className="nav_text_1"
                  >
                    {getText("nav3")}
                  </Link>
                  <Link
                    onClick={() => setBurger(!burger)}
                    to="/tarixiyhujjatlar"
                    className="nav_text_1"
                  >
                    {getText("nav4")}
                  </Link>
                  <ul class="nav__list">
                    <li class="nav__listitem">
                      {getText("nav5")}
                      <ul class="nav__listitemdrop">
                        {vil &&
                          vil.map((item, index) => {
                            return (
                              <div key={index}>
                                <li onClick={() => link(item.id)}>
                                  {item.name}
                                  {/* asdasdas */}
                                </li>
                              </div>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-3 for_sosse">
                <div className="right_navbar">
                  <div className="telegramm">
                    <FaTelegramPlane className="iconn" />
                  </div>
                  <div className="telegramm">
                    <FaFacebookF className="iconn" />
                  </div>
                  <div className="telegramm">
                    <FaYoutube className="iconn" />
                  </div>
                  <select onChange={changeLanguage} name="" id="">
                    <option value="uz" selected={getLanguage() === "uz"}>
                      O'z
                    </option>
                    <option value="ru" selected={getLanguage() === "ru"}>
                    ЎЗ
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="col-1">
             
            </div> */}
            {/* <div className="col-1"></div> */}
            <div className="col-4 d-flex justify-content-center flex-column align-items-center ">
            <div
                onClick={() => setBurger(!burger)}
                className={`burger pr-4 ml-auto d-flex d-lg-none ${
                  burger ? "burgered" : ""
                }`}
              >
                <div className="burger_up"></div>
                <div className="burger_down"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
