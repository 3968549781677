import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_PATH } from "../../tools/constant";
import "./about.css";

export default function About() {
  const [about, setAbout] = useState([]);
  const [loader, setLoader] = useState(true);

  const getNews = async () => {
    await axios
      .get(API_PATH + "sources/about-project/")
      .then((res) => {
        console.log(res);
        setAbout(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    console.log(getNews);
  };

  useEffect(() => {
    getNews();
  }, []);

  return (
    <>
      <div className="about">
        <div className="container">
          <div className="row">
            {about &&
              about.map((item, index) => (
                <div key={index} className="col-12 mx-auto">
                  <h2 className="about_h2">{item.name}</h2>
                  {/* <p className="about_p">{item.text}</p> */}
                  <div className="about_p" dangerouslySetInnerHTML={{ __html: item.text_web }} />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
