import React from 'react'
import './footer.css';
import logo from '../../componentes/images/image 1 (1).png'
import telefon from '../../componentes/images/tele.svg'
import email from '../../componentes/images/email.svg'
import loca from '../../componentes/images/loca.svg'
import tg from '../../componentes/images/teleg.svg'
import you from '../../componentes/images/youtube.svg'
import fb from '../../componentes/images/faceb.svg'
import { getText } from '../locales';
import img2 from '../../componentes/images/app.png'
import img3 from '../../componentes/images/pm.png'

export default function Footer() {
  return (
    <>
    <div className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                        <div className="top_footer">
                            <img src={logo} alt="" />
                            <p>{getText("nav9")}</p>
                        </div>
                        
                            <ul className='for_sos'> 
                                <li>
                                    <img src={img2} alt="" />
                                </li>
                                <li>
                                    <img src={img3} alt="" />
                                </li>
                                
                            </ul>
                        
                            
                        
                </div>
                <div className="col-lg-4">
                    <ul className='aloqa'>
                        <li className='aloqa_text'>{getText("fot1")}</li>
                        <li>
                            <img src={telefon} alt="" />
                           <a href="tel:+998712271548" className='hover'> +998 71 227 15 48</a></li>
                        <li>
                            <img src={email} alt="" />
                            <a href="email: info@cisc.uz" className='hover'>info@cisc.uz</a></li>
                        <li>
                            <img src={loca} alt="" />
                            <p className='hover'>{getText("fot2")}</p></li>
                    </ul>
                </div>
                <div className="col-lg-4">
                    <ul className='aloqa'>
                        <li className='aloqa_text'>{getText("fot3")}</li>
                        <li>{getText("fot4")}</li>
                        <li>{getText("fot5")}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    {/* <iframe width="2310" height="1200" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" src="https://truevirtualtours.com/ru/tour/18043/show"></iframe> */}
    
    </>
  )
}
