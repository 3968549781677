import React, { useEffect, useState } from 'react'
import Loader from '../componentes/Loader'
import TarixiyHujjatlar from '../componentes/TarixiyHujjatlar/TarixiyHujjatlar'

export default function TarixiyHujjatlarPage() {
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
      setTimeout(() => {
          setIsLoader(false)
      }, 1800);
  })
  return (
    <>
         {isLoader ? <>< Loader /> </> : <>
         <TarixiyHujjatlar />
            </>}
   
   
    </>
  )
}
