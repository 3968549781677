export const uz = {
    nav1: `Loyiha haqida`,
    nav2: `Qo'lyozmalar`,
    nav3: `Toshbosmalar`,
    nav4: `Tarixiy Hujjatlar`,
    nav5: `Reysterning hududiy manbalari`,
    nav6: `O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi O‘zbekistondagi Islom sivilizatsiyasi markazi`,
    nav7: `Toshkent shahridagi O‘zbekiston musulmonlari idorasi kutubxonasi`,
    nav8: `Alisher Navoiy nomidagi davlat adabiyot muzeyi kutubxonasi, Toshkent shahri`,
    nav9: `QADIMIY YOZMA MANBALAR DAVLAT REYESTRI`,
    
    fot1: `Aloqa`,
    fot2: `Toshkent shahar, Olmazor tumani, Qorasaroy 47`,
    fot3: `Foydali havolalar`,
    fot4: `O‘zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi O‘zbekistondagi Islom sivilizatsiyasi markazi`,
    fot5: `O‘zbekiston Islom san’ati jahon sivilizatsiyasining ajralmas qismi "Allomalar.uz" ilmiy-ma’rifiy portali`,

    stat1: `Statistkia`,
    stat2: `QADIMIY YOZMA MANBALAR DAVLAT REYESTRI, 2023`,
    stat3: `Qo'lyozmalar`,
    stat4: `Toshbosmalar`,
    stat5: `Tarixiy hujjatlar`,
    stat6: `Jami`,
    poisk: `Qidirish`,

    stat7: "Toshbosmalar ro'yhati",
    stat8: "Qo'lyozmalar ro'yhati",
    stat9: "Tarixiy hujjatlar",

    home1: `Asarlar ro’yxati`,
    home2: `ASAR NOMI`,
    home3: `MUALLIFI`,
    home4: `Ko'chirilgan yoki yozilgan sanasi`,
    home5: `Batafsil ma’lumot olish`,
    home6: `Batafsil`,
    home7: `Asar haqida`,
    home8: `Tartib raqami`,
    home9: `Asarning nomi`,
    home10: `Muallifi`,
    home11: `Xattot`,
    home12: `Mavzusi`,
    home13: `Tili`,
    home14: `Ko'chirilgan yoki yozilgan sanasi`,
    home15: `Holati`,
    home16: `O‘lchami`,
    home17: `Inventar raqami`,
    home18: `Shakli`,
    home19: `Saqlanish joyi`,
    home20: `Qo'shimcha ma'lumot`,
}