export const ru = {
    nav1: `Лойиҳа ҳақида`,
    nav2: `Қўлёзмалар`,
    nav3: `Тошбосмалар`,
    nav4: `Тарихий Ҳужжатлар`,
    nav5: `Реестернинг ҳудудий манбалари`,
    nav6: `Ўзбекистон Республикаси Вазирлар Маҳкамаси ҳузуридаги Ўзбекистондаги Ислом сивилизацияси маркази`,
    nav7: `Тошкент шаҳридаги Ўзбекистон мусулмонлари идораси кутубхонаси`,
    nav8: `Aлишер Навоий номидаги давлат адабиёт музейи кутубхонаси, Тошкент шаҳри`,
    nav9: `ҚAДИМИЙ ЁЗМA МAНБAЛAР ДAВЛAТ РЕЕСТРИ`,
    
    fot1: `Aлоқа`,
    fot2: `Тошкент шаҳар, Олмазор тумани, Қорасарой 47`,
    fot3: `Фойдали ҳаволалар`,
    fot4: `Ўзбекистон Республикаси Вазирлар Маҳкамаси ҳузуридаги Ўзбекистондаги Ислом сивилизацияси маркази`,
    fot5: `Ўзбекистон Ислом санъати жаҳон сивилизациясининг ажралмас қисми "Aлломалар.уз" илмий-маърифий портали`,

    stat1: `Статистика`,
    stat2: `ҚAДИМИЙ ЁЗМA МAНБAЛAР ДAВЛAТ РЕЙЕСТРИ, 2023`,
    stat3: `Қўлёзмалар`,
    stat4: `Тошбосмалар`,
    stat5: `Тарихий ҳужжатлар`,
    stat6: `Жами`,

    stat7: "Тошбосмалар ройхати",
    stat8: "Қўлёзмалар ройхати",
    stat9: "Тарихий ҳужжатлар",

    poisk: `Қидириш`,

    home1: `Aсарлар рўйхати`,
    home2: `AСAР НОМИ`,
    home3: `МУAЛЛИФИ`,
    home4: `Кўчирилган ёки ёзилган санаси`,
    home5: `Батафсил маълумот олиш`,
    home6: `Батафсил`,
    home7: `Aсар ҳақида`,
    home8: `Тартиб рақами`,
    home9: `Aсарнинг номи`,
    home10: `Муаллифи`,
    home11: `Хаттот`,
    home12: `Мавзуси`,
    home13: `Тили`,
    home14: `Кўчирилган ёки ёзилган санаси`,
    home15: `Ҳолати`,
    home16: `Ўлчами`,
    home17: `Инвентар рақами`,
    home18: `Шакли`,
    home19: `Сақланиш жойи`,
    home20: `Қўшимча маълумот`,
}