import React, { useEffect, useState } from 'react'
import Loader from '../componentes/Loader';
import Toshbosmalar from '../componentes/Toshbosmalar/Toshbosmalar'

export default function ToshbosmalarPage() {
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
      setTimeout(() => {
          setIsLoader(false)
      }, 1800);
  })
  return (
    <>
    {isLoader ? <>< Loader /> </> : <>
    <Toshbosmalar />
            </>}
    
    
    </>
  )
}
