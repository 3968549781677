import React, { useEffect, useState } from 'react'
import Loader from '../componentes/Loader';
import Qolyozmalar from '../componentes/Qolyozmalar/Qolyozmalar'

export default function QolyozmalarPage() {
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
      setTimeout(() => {
          setIsLoader(false)
      }, 1800);
  })
  return (
    <>
     {isLoader ? <>< Loader /> </> : <>
     <Qolyozmalar />
            </>}
    
    </>
  )
}
