
import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { API_PATH } from "../../tools/constant";


const data = [
  { name: "Group A", value: 500 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 }
];
const COLORS = ["#2D2FF0", "#B6B6B6", "#FFC300", "#B5179E"];

export default function Diagramma() {
  const [stat,setStat] = useState({})

  useEffect (() => {
    const getStat = () => {
      axios.get(API_PATH + "sources/statistics/")
        .then((res => {
          setStat(res.data)
        }))
    }
    getStat()
  },[])


  return (
    <PieChart width={300} height={400}>
      <Pie
        data={data}
        cx={120}
        cy={200}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
     
    </PieChart>
  );
}
