import axios from "axios";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./componentes/About/About";
import Footer from "./componentes/Footer/Footer";
import Modal from "./componentes/home/Modal";
import Navbar from "./componentes/Navbar/Navbar";
import Pagination from "./componentes/test";
import MainPage from "./pages/MainPage";
import QolyozmalarPage from "./pages/QolyozmalarPage";
import TarixiyHujjatlarPage from "./pages/TarixiyHujjatlarPage";
import ToshbosmalarPage from "./pages/Toshbosmalar";
import { API_PATH } from "./tools/constant";
import './style.css'
import ScrollToTop from "./componentes/ScrollToTop";
import Musulmonlar from "./componentes/home/Page1";
import Alisher from "./componentes/home/Page2";
import Islom from "./componentes/home/Page3";

function App() {

  return (
   <>
   
   <Navbar />
   <Routes>   
      <Route path="/" element={<MainPage/>} />
      {/* <Route path="/" element={<Modal />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/qolyozmalar" element={<QolyozmalarPage />} />
      <Route path="/toshbosmalar" element={<ToshbosmalarPage />} />
      <Route path="/tarixiyhujjatlar" element={<TarixiyHujjatlarPage />} />
      <Route path="/page1" element={<Musulmonlar />} />
      <Route path="/page2" element={<Alisher />} />
      <Route path="/page3" element={<Islom />} />
   </Routes>
   <Footer />
   <ScrollToTop />
   </>
  );
}

export default App;
