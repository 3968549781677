import React, { useEffect, useState } from "react";
import "./tarixiyhujjatlar.css";
import vector from "../../componentes/images/Vector2.svg";
import { AiOutlineInfoCircle } from "react-icons/ai";
import stat from "../../componentes/images/chart-graphic.png";
import fr_1 from "../../componentes/images/Rectangle 34.png";
import fr_2 from "../../componentes/images/Rectangle 34 (1).png";
import fr_3 from "../../componentes/images/Rectangle 34 (2).png";
import fr_4 from "../../componentes/images/Rectangle 34 (3).png";
import axios from "axios";
import { API_PATH } from "../../tools/constant";
import strel from "../../componentes/images/as.png";
import { GrClose } from "react-icons/gr";
import { BsSearch } from "react-icons/bs";
import Paginator from "react-hooks-paginator";
import Analiz from "../home/Analiz";
import { getText } from "../locales";

export default function Home() {
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalItem, setModalItem] = useState([]);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [search,setSearch] = useState('')
  const [viloyat,setViloyat] = useState(localStorage.getItem('VILOYAT'));

  useEffect(() => {
    const works = () => {
      if(viloyat){
        axios
        .get(API_PATH + `sources/web-docs-history/?viloyat=${viloyat}`)
        .then((res) => {
          setItems(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      }
      axios
        .get(API_PATH + `sources/web-docs-history/`)
        .then((res) => {
          setItems(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (search.length === 0 ){
      works();
    }
      searchWork();
  }, [search]);

  const searchWork = async () => {
    await axios
        .get(API_PATH + `sources/web-docs-history/?search=${search}`)
        .then((res) => {
          setItems(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }

  useEffect(() => {
    setCurrentData(items.slice(offset, offset + 8));
  }, [offset, items]);

  // const handleClick = (e) => {
  //     e.preventDefault()
  //     setModal(false)
  // }

  // console.log(modalItem);

  const handleModal = (item) => {
    setModal(true);
    window.scrollTo(0,0);
    console.log(modal);
    axios.get(API_PATH + `sources/docs-history/${item}/`).then((res) => {
      setModalItem(res.data);
      console.log(modalItem);
    });
  };

  return (
    <>
      <div className="home">
        <div className="container">
          <div className="row ">
          <form action="">
          <div className="poisk second_poisk">
               <div className="for_inp_svg">
               <input
                  value={search}
                  onChange={(e) => setSearch(() => e.target.value)}
                  type="text"
                  placeholder={getText("poisk")}
                />
              
                  <BsSearch className="svg_ab"/>
               </div>
            
              </div>
            </form>
            <h1 className="h1_home">{getText("stat9")}</h1>

            <div className="cards">
              <div className="row fro_top">
                <div className="col-lg-1 for_num">
                  <p>№</p>
                </div>
                <div className="col-lg-3">
                  <div className="for_asar">
                    <p>{getText("home2")}</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <p>{getText("home3")}</p>
                </div>
                <div className="col-lg-3">
                  <p>{getText("home4")}</p>
                </div>
                <div className="col-lg-2">
                  <p>{getText("home5")}</p>
                </div>
              </div>
              {currentData &&
                currentData.map((item, index) => {
                  return (
                    <div key={index} className="row ">
                      <div className="col-lg-1 for_num">
                        <p>{item.sort_id}</p>
                      </div>
                      <div className="col-lg-3 for_as">
                        <div className="for_asar">
                          <p>{item.work_name}</p>
                          <p className="arabian">{item.arab_name}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 for_as">
                        <p>{item.author}</p>
                      </div>
                      <div className="col-lg-3 for_as">
                        <p>{item.date_write}</p>
                      </div>
                      <div className="col-lg-2 for_btttn">
                        <button
                          onClick={() => handleModal(item.id)}
                          className="btn_batafsil btn-modal">
                          <AiOutlineInfoCircle /> {getText("home6")}
                        </button>
                      </div>
                    </div>
                  );
                })}
              <Paginator
                totalRecords={items.length}
                pageLimit={8}
                pageNeighbours={1}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>

            {currentData &&
              currentData.map((item, index) => {
                return (
                  <div className="card2">
                    <div className="container">
                      <div key={index} className="row">
                        <div className="col-lg-10 for_brd">
                          <p className="p_texxx">Asar nomi</p>
                          <p className="for_nameee">{item.work_name}</p>
                          <p className="for_arab_card2">{item.arab_name}</p>
                        </div>
                        <div className="col-lg-10 for_brd">
                          <p className="p_texxx">Muallifi</p>
                          <p className="for_nameee">{item.author}</p>
                        </div>
                        <div className="col-lg-10 for_brd">
                          <p className="p_texxx">
                            Ko'chirilgan yoki yozilgan sanasi
                          </p>
                          <p className="for_nameee">{item.date_write}</p>
                        </div>
                        <div className="col-lg-10 for_bta_2">
                          <div className="col-lg-2 for_btttn">
                            <button
                              onClick={() => handleModal(item.id)}
                              className="btn_batafsil btn-modal">
                              <AiOutlineInfoCircle /> {getText("home6")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="for_agiantion">
              <Paginator
                totalRecords={items.length}
                pageLimit={8}
                pageNeighbours={1}
                setOffset={setOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>

      <Analiz />

      {modal ? (
        <>
          <div className="ResultModal">
            <div className="container ">
              <div className="row row_1">
                <div className="for_bgg">
                  <img src={strel} alt="" />
                </div>
                <div className="for_top_modal">
                  <div className="for_toop"></div>
                  <h1 className="modal_name">{getText("home7")}</h1>
                  <GrClose
                    onClick={() => {
                      setModal(false);
                    }}
                    className="react_iconss"
                  />
                </div>
                <div className="row_items ">
                  <div className="row ">
                    <div className="col-lg-10">
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home8")}:</h2>
                        </div>
                        <div className="col-lg-6">{modalItem.sort_id}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home9")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <div className="for_asar_name">
                            <p>{modalItem.arab_name}</p>
                            <p>{modalItem.work_name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home10")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.author}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home11")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.calligrapher}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home12")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.subject}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home13")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.language}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home14")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.date_write}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home15")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.status}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home16")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.size}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home17")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.inventor_id}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home18")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.get_format}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home19")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.place_saved}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h2>{getText("home20")}:</h2>
                        </div>
                        <div className="col-lg-6">
                          <p>{modalItem.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setModal(false);
              }}
              className="shadow"></div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
